<template>
  <div>
    <div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10" v-if="showModal"></div>
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="absolute top-20 mx-auto p-5 border w-full shadow-lg rounded-md bg-white z-20" v-if="showModal">
        <div class="mt-3 text-center">
          <h3 class="text-lg font-medium font-gothic text-black text-2xl">Delete account</h3>
          <div class="space-y-4 mt-6">
            <p class="pb-2">
              Are you sure you want to delete your account?
            </p>
            <p class="pb-4">
              This action cannot be undone. You'll lose access to Hungry+ and all associated data with your account will also be deleted.
            </p>
            <button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="deleteUser">
              Delete
            </button>
            <button class="font-gothic text-gray-400 pt-1" @click="$emit('closeModal')">Cancel</button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      showModal: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {

      }
    },
    methods: {
      deleteUser(){
        this.$store.dispatch('profile/deleteUser').then( () => {
          this.$router.push({name: 'welcome'});
        }).catch( e => {
          console.error(e);
        });
      }
    }
  }
</script>