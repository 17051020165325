<template>
	<div class="h-full flex flex-col relative">
		<TopBar :showMenu="true" :showLogout="true"/>
		<AppTitle title="Profile"/>
    <ModalDeleteUser @closeModal="modalDeleteUserOpen = false" :showModal="modalDeleteUserOpen"/>
		<div class="pt-8 overflow-y-scroll flex-grow" v-if="user">
      <div class="pb-8 text-center">
        <div class="w-full">
          <user-profile-picture 
            :user="user"
            :size="32"
          />
        </div>
        <div class="mt-4 text-gray-700 font-semibold text-lg">
          {{ currentUserName }}
        </div>
      </div>
      <div class="space-y-4">
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer" @click="$router.push({name: 'edit-profile'})">
          <p class="font-bold flex-1">Personal Details</p>
          <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
        </div>
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer" @click="$router.push({name: 'change-password'})">
          <p class="font-bold flex-1">Change Password</p>
          <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
        </div>
        <!-- <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer" @click="$router.push({name: 'notification-settings'})">
          <p class="font-bold flex-1">Notification Settings</p>
          <font-awesome-icon icon="fa-solid fa-chevron-right" class=" text-sm flex-0"/>
        </div> -->
        <div class="flex bg-white rounded-xl border border-gray-100 py-4 px-4 items-center mb-4 cursor-pointer" @click="modalDeleteUserOpen=true">
          <p class="font-bold flex-1">Delete my account</p>
          <font-awesome-icon icon="fa-solid fa-exclamation-circle" class=" text-sm flex-0 text-red-500"/>
        </div>
      </div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
  import UserProfilePicture from '@/components/UserProfilePicture.vue';
  import ModalDeleteUser from '@/components/modals/ModalDeleteUser.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
      UserProfilePicture,
      ModalDeleteUser,
		},
    data(){
      return {
        modalDeleteUserOpen: false
      }
    },
    computed: {
      user(){
        return this.$store.state.auth.user;
      },
      currentUserName(){
        return this.user.name; 
      }
    },
	}
</script>